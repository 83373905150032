// extracted by mini-css-extract-plugin
export var column = "ProjectWizard__column__NzQG5";
export var container = "ProjectWizard__container__Bt2P2";
export var content = "ProjectWizard__content__vEuFt";
export var error = "ProjectWizard__error__ViMdx";
export var flex = "ProjectWizard__flex__UdsKb";
export var flexColumn = "ProjectWizard__flexColumn__iy1lZ";
export var gap1 = "ProjectWizard__gap1__eWnM5";
export var gap2 = "ProjectWizard__gap2__ZNc9a";
export var gap3 = "ProjectWizard__gap3__d0YzM";
export var gap4 = "ProjectWizard__gap4__Wz3SJ";
export var gap5 = "ProjectWizard__gap5__RN8IS";
export var h1 = "ProjectWizard__h1__r8HiG";
export var h2 = "ProjectWizard__h2__VGx0O";
export var row = "ProjectWizard__row__lLPJL";